import {combineReducers} from 'redux';
import auth from './auth';
import meals from './meals';
import admin from './admin';
import info from './info';
import stats from './stats';
import other from './other';

export default combineReducers({
  auth, meals, admin, info, stats, other,
});