import {
  MONEY_SAVED, OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW,
} from '../actions/actionTypes/types';
import { IsRunningOverwolf } from "../../utilityFunctions";
import { SendRefreshCurrentLocation } from "../../overwolf/helper/windowCommunicationHelper";


export interface OtherStateInterface {
  moneySaved: any | null;
}

const INITIAL_STATE = {
  moneySaved: null,
};


export const other = (
  state: OtherStateInterface = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {

    case MONEY_SAVED: {
      const newState = {...state, moneySaved: action.payload};
      sendOverwolfStateOverWindow(newState);
      return newState
      }
      default:
          return state;
  }
};

function sendOverwolfStateOverWindow(newState: any) {
  if (IsRunningOverwolf()) {
    SendRefreshCurrentLocation(
      OVERWOLF_REFRESH_MEALS_STATE_FROM_OTHER_WINDOW,
      newState
    );
  }
}

export default other;
